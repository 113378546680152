import { ApolloQueryResult, gql, useMutation } from "@apollo/client";
import { Delete, Key as KeyIcon } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { AllKeysQueryResponse, Key, MyKeysQueryResponse } from "../Types/Key";

interface KeyCardProps {
  keyData: Key;
  refetch: () => Promise<
    ApolloQueryResult<MyKeysQueryResponse | AllKeysQueryResponse>
  >;
}

function useOnKeyDelete(
  name: String,
  refresh: () => Promise<any>,
  deleteCb: () => Promise<any>
) {
  const toastId = useRef<any>(null);
  return useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (toast.isActive(toastId.current)) return;
      toastId.current = toast(
        `Are you sure you want to delete ${name}? Click here to delete.`,
        {
          type: "error",
          autoClose: 15000,
          position: "top-center",
          onClick: async () => {
            await deleteCb();
            toast("Key deleted!", { type: "success" });
            refresh();
          },
        }
      );
    },
    [name, refresh, deleteCb]
  );
}

const DELETE_KEY = gql`
  mutation DeleteKey($id: String!) {
    DeleteKey(id: $id)
  }
`;

const KeyCard = React.memo<KeyCardProps>(
  ({ keyData: { key, id, name }, refetch }) => {
    const [deleteFunction] = useMutation(DELETE_KEY, {
      variables: { id },
      onCompleted: refetch,
    });
    const onDelete = useOnKeyDelete(name, refetch, deleteFunction);

    const onClickKey = useCallback(
      async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        await navigator.clipboard.writeText(key);
        toast("API Key copied to clipboard", { type: "success" });
      },
      [key]
    );

    return (
      <Box className="client__card" sx={{ boxShadow: 1, borderRadius: 1 }}>
        <Typography component="div" className="client__cardname">
          {name}
          <Typography component="div" className="client__cardname--id">
            {id}
          </Typography>
        </Typography>
        <div className="client__cardactions">
          <IconButton
            title="Copy API Key"
            className="client__cardaction"
            onClick={onClickKey}
          >
            <KeyIcon fontSize="large" />
          </IconButton>
          <IconButton
            title="Delete Key"
            className="client__cardaction client__cardaction--delete"
            onClick={onDelete}
            color="error"
          >
            <Delete fontSize="large" />
          </IconButton>
        </div>
      </Box>
    );
  }
);

export default KeyCard;
