import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import { userState } from "../State/atoms";

function useOnAuthClick(url: string): [() => Promise<string>, boolean] {
  const [onClick, loading] = useAsyncRequest(() => api.oAuthLogin(url));
  return [
    useCallback(async () => {
      const resp = await onClick();
      return resp;
    }, [onClick]),
    loading,
  ];
}

export default function useOAuthPopup(
  url: string
): [() => Promise<void>, boolean, Window | null] {
  const [onClick, loading] = useOnAuthClick(url);
  const [popup, setPopup] = useState<Window | null>(null);
  const interval = useRef<any>();
  const toastId = useRef<any>();
  const [user, setUser] = useRecoilState(userState);

  const openPopup = useCallback(async () => {
    if (user) {
      toast("You are already logged in. Try refreshing.", { type: "error" });
      return;
    }
    try {
      const url = await onClick();
      const width = 800;
      const height = 700;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const title = `Liquipedia OAuth Login`;
      const _popup = window.open(
        url,
        title,
        `width=${width},height=${height},top=${top},left=${left}`
      );
      if (_popup) {
        toastId.current = toast("Use popup window to login", {
          type: "info",
          autoClose: false,
        });
        setPopup(_popup);
      }
    } catch (error) {
      console.error(error);
    }
  }, [onClick, user]);
  const onClose = useCallback(() => {
    if (popup) popup.close();
    clearInterval(interval.current);
    setPopup(null);
    toast.dismiss(toastId.current);
    toastId.current = null;
  }, [popup]);
  const onToken = useCallback(
    async (token: string) => {
      await api.onLogin(token);
      const _user = await api.me();
      setUser(_user);
      toast.update(toastId.current, {
        render: "Logged in!",
        type: "success",
        autoClose: 1000,
      });
      onClose();
    },
    [setUser, onClose]
  );

  useEffect(() => {
    if (!popup || user) return;
    if (interval.current) clearInterval(interval.current);
    interval.current = setInterval(() => {
      if (popup.closed || !popup) {
        onClose();
        return;
      }
      console.log(popup);
      try {
        const currentUrl = popup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const token = searchParams.get("token");
        if (token) {
          onToken(token);
        }
      } catch (error) {
        return;
      }
    }, 500);
  }, [popup, user, onToken, onClose]);

  return [openPopup, loading, popup];
}
