import { AddCircle } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import Social, { SocialType } from "../Types/Social";
import AuthWrapper from "../Wrappers/AuthWrapper";
import ClientContext from "./ClientContext";
import SocialForm from "./SocialForm";

interface ClientData {
  name: string;
  redirect_url: string;
}

function validateRedirect(redirect_url: string): string | undefined {
  if (redirect_url.length === 0) return;

  try {
    new URL(redirect_url);
  } catch (_) {
    return "Redirect URL must be a valid URL";
  }

  // return url.protocol === "http:" || url.protocol === "https:"
  //   ? undefined
  //   : "Redirect URL does not have a valid protocol";
  return;
}

const defaultClient: ClientData = {
  name: "",
  redirect_url: "",
};

const ClientForm = React.memo(() => {
  const ctx = useContext(ClientContext);
  const isEditMode = Boolean(ctx && ctx.current);
  const [data, setData] = useState<ClientData>(
    isEditMode ? ctx!.current! : defaultClient
  );
  const [socials, setSocials] = useState<Social[]>(
    isEditMode ? ctx!.current!.socials : []
  );
  const navigate = useNavigate();
  const [errors, setErrors] = useState<(string | undefined)[]>([
    undefined,
    undefined,
  ]);
  const validate = useCallback(() => {
    const newErrors = [undefined, validateRedirect(data.redirect_url)];
    setErrors(newErrors);
    return newErrors.every((err) => typeof err === "undefined");
  }, [data]);

  const onEditSocial = useCallback(
    (index: number, social: Social) => {
      const newSocials = [...socials];
      newSocials[index] = social;
      setSocials(newSocials);
    },
    [socials]
  );
  const addSocial = useCallback(
    () =>
      setSocials([
        ...socials,
        { type: SocialType.Google, client_id: "", client_secret: "" },
      ]),
    [socials]
  );
  const removeSocial = useCallback(
    (index: number) => {
      const newSocials = [...socials];
      newSocials.splice(index, 1);
      setSocials(newSocials);
    },
    [socials]
  );

  const submit = useCallback(async () => {
    if (!validate()) return;

    try {
      const client = isEditMode
        ? await api.updateClient(
            ctx!.current!.id,
            data.redirect_url,
            data.name,
            socials
          )
        : await api.newClient(data.redirect_url, data.name, []);
      toast(
        isEditMode
          ? "Client updated successfully"
          : "Client created successfully!",
        { type: "success" }
      );
      if (!isEditMode) {
        navigate(`/client/${client.id}`);
      } else {
        ctx!.fetch!();
      }
    } catch (error) {
      const { message } = error as Error;
      toast(message, { type: "error" });
    }
  }, [data, validate, navigate, ctx, isEditMode, socials]);
  const [onSubmit, loading] = useAsyncRequest(submit);

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setData({ ...data, [name]: value });
    },
    [data]
  );

  const onBlur = useCallback(
    (e) => {
      e.preventDefault();
      validate();
    },
    [validate]
  );

  return (
    <AuthWrapper roles={["admin", "dev"]}>
      <Box className="container__card" sx={{ boxShadow: 2, marginTop: "30px" }}>
        <div className="register__header">
          {isEditMode ? `Update ${ctx!.current!.name}` : "Create New Client"}
        </div>
        <form
          className="register__form"
          onSubmit={onSubmit}
          style={{ paddingTop: "25px", paddingBottom: "25px" }}
        >
          <TextField
            label="Client Name"
            variant="outlined"
            autoFocus={!isEditMode}
            type="text"
            className="register__input"
            required
            name="name"
            onChange={onChange}
            error={Boolean(errors[0])}
            helperText={errors[0]}
            onBlur={onBlur}
            value={data.name}
          />
          <TextField
            label="Redirect URL"
            variant="outlined"
            type="text"
            className="register__input"
            required
            name="redirect_url"
            onChange={onChange}
            error={Boolean(errors[1])}
            helperText={errors[1]}
            onBlur={onBlur}
            value={data.redirect_url}
          />
          {isEditMode && (
            <>
              <div className="register__header" style={{ marginTop: "20px" }}>
                Social Credentials
              </div>
              <Box className="client__socials">
                {socials.map((social, index) => (
                  <SocialForm
                    onChange={(updated) => onEditSocial(index, updated)}
                    social={social}
                    key={`socialform-${index}`}
                    removeSocial={() => removeSocial(index)}
                  />
                ))}
                <Button
                  className="client__socialcontainer client__socialcontainer--new"
                  variant="outlined"
                  onClick={addSocial}
                >
                  <AddCircle fontSize="inherit" />
                </Button>
              </Box>
            </>
          )}
          <LoadingButton
            variant="contained"
            className="register__submit"
            type="submit"
            loading={loading}
            disabled={
              errors.some((error) => Boolean(error)) ||
              data.name.length === 0 ||
              data.redirect_url.length === 0
            }
          >
            Submit
          </LoadingButton>
        </form>
      </Box>
    </AuthWrapper>
  );
});

export default ClientForm;
