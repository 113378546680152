import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { RecoilRoot } from "recoil";
import LoggedInWrapper from "./Wrappers/LoggedInWrapper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/client";
import client from "./Gql/client";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        pauseOnHover
        pauseOnFocusLoss
      />
      <RecoilRoot>
        <ApolloProvider client={client}>
          <Router>
            <LoggedInWrapper>
              <Routes />
            </LoggedInWrapper>
          </Router>
        </ApolloProvider>
      </RecoilRoot>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
