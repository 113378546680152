import React from "react";
import SocialIcon from "../Statics/SocialIcon";
import { SocialType } from "../Types/Social";
import OAuthLoginPopupButton from "./OAuthLoginPopupButton";

export interface OAuthLoginProps {
  label: string;
  icon: JSX.Element;
  url: string;
  className?: string;
}

export const OAuthLogins = [
  {
    label: "Google",
    icon: <SocialIcon social={SocialType.Google} />,
    url: "/google/login",
    className: "background-google",
  },
  {
    label: "Reddit",
    icon: <SocialIcon social={SocialType.Reddit} />,
    url: "/reddit/login",
    className: "background-reddit",
  },
  {
    label: "Twitch",
    icon: <SocialIcon social={SocialType.Twitch} />,
    url: "/twitch/login",
    className: "background-twitch",
  },
  {
    label: "Apple",
    icon: <SocialIcon social={SocialType.Apple} />,
    url: "/apple/login",
    className: "background-apple--alt",
  },
  {
    label: "Twitter",
    icon: <SocialIcon social={SocialType.Twitter} />,
    url: "/twitter/login",
    className: "background-twitter",
  },
  {
    label: "Discord",
    icon: <SocialIcon social={SocialType.Discord} />,
    url: "/discord/login",
    className: "background-discord",
  },
];

const Login = React.memo(() => {
  return (
    <div className="login__oauthselection">
      {OAuthLogins.map((oAuthLogin) => (
        <OAuthLoginPopupButton
          {...oAuthLogin}
          key={`login-${oAuthLogin.label}`}
        />
      ))}
    </div>
  );
});

export default Login;
