import { ApolloQueryResult, gql, useMutation } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Key, MyKeysQueryResponse } from "../Types/Key";
import AuthWrapper from "../Wrappers/AuthWrapper";

type KeyData = Pick<Key, "name">;

const GENERATE_KEY = gql`
  mutation GenerateKey($name: String!) {
    GenerateKey(name: $name) {
      id
    }
  }
`;

interface KeyFormProps {
  refetch: () => Promise<ApolloQueryResult<MyKeysQueryResponse>>;
}

const KeyForm = React.memo<KeyFormProps>(({ refetch }) => {
  const [data, setData] = useState<KeyData>({ name: "" });
  const [generate, { loading }] = useMutation(GENERATE_KEY, {
    onCompleted: refetch,
  });

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await generate({ variables: data });
        toast("Key created successfully", { type: "success" });
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
      }
    },
    [data, generate]
  );

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setData({ ...data, [name]: value });
    },
    [data]
  );

  return (
    <AuthWrapper roles={["admin", "dev"]}>
      <Box className="container__card" sx={{ boxShadow: 2, marginTop: "30px" }}>
        <div className="register__header">Generate New Key</div>
        <form
          className="register__form"
          onSubmit={submit}
          style={{ paddingTop: "25px", paddingBottom: "25px" }}
        >
          <TextField
            label="Key Name"
            variant="outlined"
            type="text"
            className="register__input"
            required
            name="name"
            onChange={onChange}
            value={data.name}
          />
          <LoadingButton
            variant="contained"
            className="register__submit"
            type="submit"
            loading={loading}
            disabled={data.name.length === 0}
          >
            Generate
          </LoadingButton>
        </form>
      </Box>
    </AuthWrapper>
  );
});

export default KeyForm;
