import { LoadingButton } from "@mui/lab";
import React, { useCallback } from "react";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import { OAuthLoginProps } from "./OAuthLogin";

function useOnAuthClick(url: string): [() => Promise<void>, boolean] {
  const [onClick, loading] = useAsyncRequest(() => api.oAuthLogin(url));

  return [
    useCallback(async () => {
      const resp = await onClick();
      if (resp) {
        window.location = resp;
      }
    }, [onClick]),
    loading,
  ];
}

export default function OAuthLoginButton({
  url,
  icon,
  label,
  className,
}: OAuthLoginProps) {
  const [onClick] = useOnAuthClick(url);

  return (
    <LoadingButton
      className={`login__oauthbutton${className ? ` ${className}` : ""}`}
      variant="contained"
      endIcon={icon}
      onClick={onClick}
    >
      Login with {label}
    </LoadingButton>
  );
}
