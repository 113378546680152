import { JWE, JWK } from "node-jose";

const clientId: String = "61f3c27fb5fa865156112735";
const publicKey: String =
  "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoiAbByAXX+iy0dAnBRY8\ngHNCf79iH6+M/os/waapB3idDFneW/5OYeV4VjjiQhaeQPmdEsPR5SgRohlXMbAw\n9mYKduTAb/l9GGZ3UqpxinHZO3RnxQgt2vNBWsSe7zRwQb5sSW2Rr2KYQgsHUFj2\nJiHUxAKQiVQALI19rJGVvTjotg4OmbCPgdDIZVYsE2iT6OjlmKiENGwBCwL7uqmX\newk+7OeT77P/1rDlqdAvpDSMJxyxou2D+iPh4uwtL4RrrrZ+p5QwKoo69K2FDoe1\nexMeLCuojOPB1SADVhpAeYk5yktgheRT+SvpZ7cnHRJfneuualqzpVfFS57Tbrqc\nAwIDAQAB\n-----END PUBLIC KEY-----";

export const createHeader = async (payload: any = ""): Promise<string> => {
  const pubKey = await JWK.asKey(publicKey, "pem");
  const token = await JWE.createEncrypt({ format: "compact" }, pubKey)
    .update(payload)
    .final();
  const authPayload = {
    id: clientId,
    token,
  };

  return JSON.stringify(authPayload);
};
