import config from "../config";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  HttpLink,
} from "@apollo/client";
import api from "../Api/api";

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authentication: api.jwt,
    },
  }));

  return forward(operation);
});

const baseURL = config.get("gql_url") ?? config.getGQLUrl();
console.log(baseURL);
const cache = new InMemoryCache();
const httpLink = new HttpLink({ uri: baseURL });

const client = new ApolloClient({
  uri: baseURL,
  cache,
  link: concat(authMiddleware, httpLink),
});

export default client;
