import { gql, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import React from "react";
import { AllKeysQueryResponse } from "../Types/Key";
import KeyCard from "./KeyCard";

const GET_KEYS = gql`
  query {
    AllKeys {
      key
      name
      id
    }
  }
`;

const AllKeys = React.memo(() => {
  const { loading, error, data, refetch } =
    useQuery<AllKeysQueryResponse>(GET_KEYS);

  if (loading) return <div>Loading...</div>;
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <div>
      <Typography variant="h3" sx={{ margin: "20px 0px" }}>
        All Keys
      </Typography>
      {data
        ? data.AllKeys.map((val) => (
            <KeyCard keyData={val} key={`keys-${val.id}`} refetch={refetch} />
          ))
        : "No Keys"}
    </div>
  );
});

export default AllKeys;
