import { gql, ServerError, useMutation } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import AuthWrapper from "../Wrappers/AuthWrapper";

const REMOVE_ROLE = gql`
  mutation RemoveRole($roles: [String]!, $userId: String!) {
    RemoveRole(roles: $roles, userId: $userId) {
      userId
    }
  }
`;

interface FormData {
  roles: string;
  userId: string;
}

const defaultState: FormData = {
  roles: "",
  userId: "",
};

const RemoveRoleForm = React.memo(() => {
  const [data, setData] = useState<FormData>(defaultState);
  const [add, { loading }] = useMutation(REMOVE_ROLE, {
    onCompleted: () => {
      toast("Role removed successfully", { type: "success" });
      setData(defaultState);
    },
    onError: ({ networkError }) => {
      const {
        result: { errors },
      } = networkError as ServerError;
      // toast(message, { type: "error" });
      errors.forEach((err: any) => toast(err.message, { type: "error" }));
    },
  });

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      await add({ variables: { ...data, roles: data.roles.split(", ") } });
    },
    [data, add]
  );

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setData({ ...data, [name]: value });
    },
    [data]
  );

  return (
    <AuthWrapper roles={["admin", "dev"]}>
      <Box
        className="container__card"
        sx={{ boxShadow: 2, margin: "30px 0px" }}
      >
        <div className="register__header">Remove User Roles</div>
        <form
          className="register__form"
          onSubmit={submit}
          style={{ paddingTop: "25px", paddingBottom: "25px" }}
        >
          <TextField
            label="User ID"
            variant="outlined"
            type="text"
            className="register__input"
            required
            name="userId"
            onChange={onChange}
            value={data.userId}
          />
          <TextField
            label="Roles"
            variant="outlined"
            type="text"
            className="register__input"
            required
            name="roles"
            onChange={onChange}
            value={data.roles}
            helperText="Comma separated roles"
          />
          <LoadingButton
            variant="contained"
            className="register__submit"
            type="submit"
            loading={loading}
            disabled={data.userId.length === 0 || data.roles.length === 0}
          >
            Remove
          </LoadingButton>
        </form>
      </Box>
    </AuthWrapper>
  );
});

export default RemoveRoleForm;
