import React from "react";
import AddRoleForm from "../Admin/AddRoleForm";
import RemoveRoleForm from "../Admin/RemoveRoleForm";
import AllKeys from "../Keys/AllKeys";
import Keys from "../Keys/Keys";
import PageContainer from "../Statics/PageContainer";
import AuthWrapper from "../Wrappers/AuthWrapper";

export default function Home() {
  return (
    <PageContainer>
      <AuthWrapper roles={["dev"]} render={false}>
        <Keys />
      </AuthWrapper>
      <AuthWrapper roles={["admin"]} render={false}>
        <AllKeys />
        <AddRoleForm />
        <RemoveRoleForm />
      </AuthWrapper>
    </PageContainer>
  );
}
