import { Axios } from "axios";
import { User } from "../State/atoms";
import { createHeader } from "./jwe";
import Cookies from "universal-cookie";
import Client, { PopulatedClient } from "../Types/Client";
import Social from "../Types/Social";
import config from "../config";

const cookies = new Cookies();

const baseURL = config.get("api_url") ?? config.getAPIUrl();
console.log(baseURL);
const validRespStatus = [200, 201, 204];

class Api extends Axios {
  jwt: string = "";
  refresh: string = "";

  constructor() {
    super({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  // Attaches JWE[JWS] headers
  private async _post(url: string, data?: any, payload: string = "") {
    const Authorization = await createHeader(
      payload && payload.length > 0 ? payload : this.jwt
    );
    const resp = await this.post(url, JSON.stringify(data), {
      headers: { Authorization },
    });
    if (!validRespStatus.includes(resp.status)) throw Error(resp.data);
    return resp;
  }

  // Attaches JWE[JWS] headers
  private async _put(url: string, data?: any, payload: string = "") {
    const Authorization = await createHeader(
      payload && payload.length > 0 ? payload : this.jwt
    );
    const resp = await this.put(url, JSON.stringify(data), {
      headers: { Authorization },
    });
    if (!validRespStatus.includes(resp.status)) throw Error(resp.data);
    return resp;
  }

  // Attaches JWE[JWS] headers
  private async _patch(url: string, data?: any, payload: string = "") {
    const Authorization = await createHeader(
      payload && payload.length > 0 ? payload : this.jwt
    );
    const resp = await this.patch(url, JSON.stringify(data), {
      headers: { Authorization },
    });
    if (!validRespStatus.includes(resp.status)) throw Error(resp.data);
    return resp;
  }

  // Attaches JWE[JWS] headers
  private async _get(url: string, params?: any, payload: any = "") {
    const Authorization = await createHeader(
      payload && payload.length > 0 ? payload : this.jwt
    );
    const resp = await this.get(url, {
      headers: { Authorization },
      params,
    });
    if (!validRespStatus.includes(resp.status)) throw Error(resp.data);
    return resp;
  }

  // Attaches JWE[JWS headers]
  private async _delete(url: string, params?: any, payload: any = "") {
    const Authorization = await createHeader(
      payload && payload.length > 0 ? payload : this.jwt
    );
    return this.delete(url, {
      headers: { Authorization },
      params,
    });
  }

  async getUserByEmail(email: string): Promise<User> {
    const resp = await this._get("/user/email", { email });

    return JSON.parse(resp.data);
  }

  async getUser(id: string): Promise<User> {
    console.log("get user", id);
    const resp = await this._get(`/user/${id}`);

    return JSON.parse(resp.data);
  }

  async addUserRole(userId: string, roles: string[]) {
    const resp = await this._post(`/user/addrole/${userId}`, { roles });

    return resp;
  }

  async removeUserRole(userId: string, roles: string[]) {
    const resp = await this._post(`/user/removerole/${userId}`, { roles });

    return resp;
  }

  async register(email: string, password: string) {
    const registered = await this._post("/default/register", {
      email,
      password,
    });

    return registered;
  }

  async registrationVerify(code: string) {
    const resp = await this._post("/default/verify", { code });

    return JSON.parse(resp.data);
  }

  async oAuthLogin(url: string, scopes: string[] = []) {
    const redirect = await this._post(url, { scopes });

    return redirect.data;
  }

  async emailLogin(email: string) {
    const resp = await this._post("/email/login", { email });

    return resp;
  }

  async emailVerify(code: string) {
    const resp = await this._post("/email/verify", { code });
    return JSON.parse(resp.data);
  }

  async defaultLogin(email: string, password: string) {
    const resp = await this._post("/default/login", { email, password });
    return JSON.parse(resp.data);
  }

  async onLogin(code: string): Promise<{ jwt: string; refresh_token: string }> {
    const resp = await this._post("/auth/code", { code });
    const { jwt, refresh_token } = JSON.parse(resp.data);
    this.jwt = jwt;
    this.refresh = refresh_token;

    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    cookies.set("jwt", this.jwt, { expires, path: "/" });
    cookies.set("refresh_token", this.refresh, { expires, path: "/" });

    return JSON.parse(resp.data);
  }

  async refreshTokens(): Promise<void> {
    const resp = await this._post("/auth/refresh", {
      refresh_token: this.refresh,
    });

    const { jwt, refresh_token } = JSON.parse(resp.data);
    console.log("JWT:", jwt);
    this.jwt = jwt;
    this.refresh = refresh_token;
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    cookies.set("jwt", this.jwt, { expires, path: "/" });
    cookies.set("refresh_token", this.refresh, { expires, path: "/" });
  }

  async logout(): Promise<any> {
    await this._post("/user/logout");
    this.jwt = "";
    cookies.remove("jwt");
  }

  async me(): Promise<User | undefined> {
    if (!this.jwt) {
      return undefined;
    }

    const resp = await this._get("/user/me", undefined);

    return JSON.parse(resp.data);
  }

  async getClients() {
    const resp = await this._get("/client/g/mine");

    return JSON.parse(resp.data);
  }

  async newClient(redirect_url: string, name: string, socials: Social[]) {
    const resp = await this._post("/client", {
      redirect_url,
      name,
      socials,
    });

    return JSON.parse(resp.data);
  }

  async updateClient(
    id: string,
    redirect_url: string,
    name: string,
    socials: Social[]
  ) {
    const resp = await this._patch(`/client/${id}`, {
      redirect_url,
      name,
      socials,
    });

    return JSON.parse(resp.data);
  }

  async deleteClient(id: string) {
    const resp = await this._delete(`/client/${id}`);

    return resp;
  }

  async regenerateClientTokens(id: string) {
    const resp = await this._post(`/client/${id}/regeneratetokens`);

    return JSON.parse(resp.data);
  }

  async getClient(id: string): Promise<PopulatedClient> {
    const resp = await this._get(`/client/${id}`);

    return JSON.parse(resp.data);
  }

  async searchClients(name: string, creator: string): Promise<Client[]> {
    const resp = await this._get("/client/g/search", {
      name: name.length > 0 ? name : undefined,
      creator: creator.length > 0 ? creator : undefined,
    });

    return JSON.parse(resp.data);
  }

  async addConnectedClient(id: string, clientId: string) {
    const resp = await this._put(`/client/${id}/addct`, { client: clientId });

    return JSON.parse(resp.data);
  }

  async removeConnectedClient(id: string, clientId: string) {
    const resp = await this._put(`/client/${id}/removect`, {
      client: clientId,
    });

    return JSON.parse(resp.data);
  }

  async addAccessUser(id: string, userId: string) {
    const resp = await this._put(`/client/${id}/add/${userId}`);

    return JSON.parse(resp.data);
  }

  async removeAccessUser(id: string, userId: string) {
    const resp = await this._put(`/client/${id}/remove/${userId}`);

    return JSON.parse(resp.data);
  }
}

const api = new Api();

export default api;
