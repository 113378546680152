import { LoadingButton } from "@mui/lab";
import React from "react";
import { OAuthLoginProps } from "./OAuthLogin";
import OAuthLoginButton from "./OAuthLoginButton";
import useOAuthPopup from "./useOAuthPopup";

export default function OAuthLoginPopupButton({
  url,
  icon,
  label,
  className,
}: OAuthLoginProps) {
  const [onClick] = useOAuthPopup(url);

  if (url === "/twitter/login") {
    return (
      <OAuthLoginButton
        url={url}
        icon={icon}
        label={label}
        className={className}
      />
    );
  }
  return (
    <LoadingButton
      variant="contained"
      endIcon={icon}
      className={`login__oauthbutton${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      Login with {label}
    </LoadingButton>
  );
}
